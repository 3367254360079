import LicenseEncrypter from 'eflex-license-manager/services/license-encrypter';
import { dasherize } from '@ember/string';

export default class LegacyLicenseEncrypter extends LicenseEncrypter {
  serializeLicense(license) {
    const json = {
      licenseId: license.licenseId,
      customer: license.customer,
      project: license.project,
      jobNumber: license.jobNumber,
      greenKey: license.greenKey,
      isVirtual: license.isVirtual,
    };

    license.products.forEach((product) => {
      json[product.name] = product.json;
    });

    return JSON.stringify(json);
  }

  getLicenseFilename(license) {
    const safeCustomerName = this._sanitizeString(dasherize(license.customer));
    const safeProject = this._sanitizeString(dasherize(license.project));

    return `${safeCustomerName}_${safeProject}_${license.licenseId}.txt`;
  }
}
