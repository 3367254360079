import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';

export default class LoginController extends Controller {
  @service mongodbRealm;
  @service session;

  email;
  password;

  login = task(waitFor(async () => {
    try {
      const data = {};

      if (this.email && this.password) {
        data.email = this.email;
        data.password = this.password;
      }

      await this.session.authenticate('authenticator:mongodb-realm', data);
    } catch (e) {
      if (e != null) {
        console.error(e);
      }
    }
  }));
}
