import Base from 'ember-simple-auth/authenticators/base';
import * as Realm from 'realm-web';
import { inject as service } from '@ember/service';

export default class MongodbRealmAuthenticator extends Base {
  @service mongodbRealm;
  @service router;

  //TODO: restoring a stale session (>= 24hrs) causes realm to give a 401 initially, but ends up authenticating anyway
  restore(data) {
    if (this.mongodbRealm.currentUser?.isLoggedIn && data.id === this.mongodbRealm.currentUser?.id) {
      return Promise.resolve(this.mongodbRealm.currentUser.toJSON());
    } else {
      return Promise.reject(new Error('Cannot Restore Mongo Realm Auth Session'));
    }
  }

  async authenticate(data) {
    let user;

    if (this.mongodbRealm.apiKeyAuth) {
      user = await this._authenticateWithApiKey(data.apiKey);
    } else if (data.email && data.password) {
      user = await this._authenticateWithEmailPass(data.email, data.password);
    } else {
      user = await this._authenticateWithGoogle();
    }

    return user.toJSON();
  }

  invalidate() {
    return this.mongodbRealm.logout();
  }

  _resolveAuthenticationFromRedirect(data) {
    if (data?.user?.isLoggedIn) {
      return data.user;
    }
  }

  async _authenticateWithGoogle() {
    if (this.mongodbRealm.currentUser?.isLoggedIn) {
      return this.mongodbRealm.currentUser;
    }

    const redirectUrl = globalThis.location.origin + this.router.urlFor('handleOauth');
    const credentials = Realm.Credentials.google({ redirectUrl });
    return await this.mongodbRealm.logIn(credentials);
  }

  async _authenticateWithEmailPass(email, password) {
    if (this.mongodbRealm.currentUser?.isLoggedIn) {
      return this.mongodbRealm.currentUser;
    }

    const credentials = Realm.Credentials.emailPassword(email, password);
    return await this.mongodbRealm.logIn(credentials);
  }

  async _authenticateWithApiKey(key) {
    try {
      const credentials = Realm.Credentials.apiKey(key);
      // eslint-disable-next-line no-unused-vars
      return await this.mongodbRealm.logIn(credentials);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      throw e;
    }
  }
}
